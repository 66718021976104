import { Component, useEffect } from 'react';
import AppContext from '../AppContext';
import LandingPage from './LandingPage';
import AppContainer from './AppContainer';
import HomePage from './HomePage';
import { jwtDecode } from 'jwt-decode';
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import RecoverForm from './cadastros/RecoverForm';

const defaultState = {
  usuario: null,
  accessToken: null,
  refreshToken: null,
  menuClosed: false,
  toasts: [],
  content: null,
  eventos: [],
  avisos: [],
  loading: false,
  configuracoes: {
    ENDPOINT_URL: process.env.REACT_APP_BACKEND_URL,
    TINYMCE_APIKEY: "8ptgrfhsh5zkzmm5uv1jqfyng0sleghxefx3hopichbl63ql",
    GOOGLE_SITE_KEY: "6LfOoM4ZAAAAAMnFZfPMPNSquP1vofRsTIdsRnNk"
  },
  contents: [],
  modal: {
    show: false
  },
  contentKey: 0,
  filters: []
}

class App extends Component {

  state = {
    ...defaultState,
    changeFilter: (label, value) => this.setState(state => ({
      filters: state.filters.find(f => f.label === label)? 
      state.filters.map(f => f.label === label? {...f, value} : f):
      [...state.filters, {label, value}]
    })),
    logon: async ({ classe, login, senha }) => {
      const captcha = await this.state.getCaptcha("LOGON");
      this.state.request("POST", "/auth", { classe, login, senha, captcha }).then(response => {
        this.setState({
          usuario: response.Usuario,
          refreshToken: response.refreshToken,
          accessToken: response.accessToken
        }, () => this.state.setContent(<HomePage />));
      });
    },
    logoff: () => this.setState(defaultState, () => this.state.setContent(<LandingPage logoff />)),
    isAuthorized: recurso => this.state.usuario?.Recursos.find(r => r.nome === recurso),
    toggleMenu: () => this.setState(state => ({ menuClosed: !state.menuClosed })),
    setContent: content => {
      this.setState(state => ({
        contentKey: state.contentKey + 1,
        content,
        contents: [...state.contents, content]
      }), () => {
        window.history.pushState({ index: this.state.contents.length - 1 }, "", "");
        window.scrollTo({ top: 0, behavior: 'instant' });
      });
    },
    addToast: toast => this.setState(state => ({ toasts: [...state.toasts, toast] })),
    hideToast: toast => this.setState(state => ({ toasts: state.toasts.filter(t => t !== toast) })),
    openModal: ({ title, body, params }) => this.setState({ modal: { title, body, params, show: true } }),
    closeModal: () => this.setState({ modal: { show: false } }),
    refresh: () => new Promise((resolve, reject) => {
      fetch(this.state.configuracoes.ENDPOINT_URL + "/auth/refresh", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.state.refreshToken}`
        }
      })
        .then(response => response.json())
        .then(({ Usuario, accessToken }) => {
          this.setState({ usuario: Usuario, accessToken }, resolve)
        })
        .catch(reject);
    }),
    load: promises => new Promise((resolve, reject) =>
      this.setState(
        { loading: true },
        () =>
          Promise.allSettled(promises)
            .then(resolve)
            .catch(reject)
            .finally(() => this.setState({ loading: false }))
      )
    ),
    request: (method, url, payload, onError) => new Promise(async (resolve, reject) => {

      const timeout = setTimeout(() => this.setState({ loading: true }), 200);

      const reqTimeout = setTimeout(() => {
        const message = "Tempo Excedido para execução da requisição";
        this.setState({ loading: false });
        if (onError) onError(new Error(message));
        reject(message);
      }, 180000);

      const body = (method !== "GET" ? (payload instanceof FormData ? payload : JSON.stringify(payload)) : undefined);
      const headers = (payload instanceof FormData ? undefined : { "Content-Type": "application/json" });


      if (this.state.accessToken) {
        const decoded = jwtDecode(this.state.accessToken);
        if (new Date(decoded.exp * 1000) < new Date()) await this.state.refresh();
      }

      const sendRequest = () => fetch(this.state.configuracoes.ENDPOINT_URL + url, {
        method,
        body,
        headers: {
          Authorization: `Bearer ${this.state.accessToken}`,
          ...headers
        }
      });

      try {
        var response = await sendRequest();
        var result;

        if (!response.ok) {

          //Verifica se o token expirou e atualiza
          if (response.status === 401) {
            await this.state.refresh();
            response = await sendRequest();
          } else {
            throw new Error(await response.text());
          }
        }

        // Verifica o tipo de resposta e converte no objeto apropriado
        const contentType = response.headers.get('content-type');
        if (contentType?.includes('application/json')) {
          result = await response.json();
        } else if (contentType?.includes('application/octet-stream')) {
          result = await response.blob();
        }

        resolve(result);

      } catch (error) {
        this.state.addToast({ header: "Erro!", body: error.message, /*autohide: false*/ });
        if (onError) onError(error);
      } finally {
        clearTimeout(timeout);
        clearTimeout(reqTimeout);
        this.setState({ loading: false })
      }

    }),
    getCaptcha: action => new Promise(async (resolve, reject) => {

      const timeout = setTimeout(() => {
        return reject("Recaptcha não carregado!");
      }, 10000);

      while (!window.grecaptcha) await new Promise(resolve => setTimeout(resolve, 500));
      clearTimeout(timeout);

      try {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(this.state.configuracoes.GOOGLE_SITE_KEY, { action })
            .then(resolve)
            .catch(reject);
        });
      } catch (error) {
        reject('Erro ao executar reCAPTCHA: ' + error);
      }

    })
  }

  popstate = (event) => {
    if (event.state) {
      const { index } = event.state;
      if (index === 0) this.state.logoff();
      else this.setState(state => ({ content: state.contents[index] }), () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
      });
    }
  }

  componentDidMount() {
    this.loadReCaptchaScript();
    window.addEventListener('popstate', this.popstate);
    this.state.setContent(<LandingPage />);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.popstate);
  }

  redirectBoleto = () => {
    const { protocolo } = useParams();
    const url = `${this.state.configuracoes.ENDPOINT_URL}/boletos/regin/${protocolo}`;
    useEffect(() => {
      window.location.href = url;
    }, [url])
    return null;
  }

  redirectCertificado = () => {
    const { hash } = useParams();
    const url = `${this.state.configuracoes.ENDPOINT_URL}/certificados/download/${hash}`;
    useEffect(() => {
      window.location.href = url;
    }, [url])
    return null;
  }

  redirectVerificar = () => {

    const { id } = useParams();
    const url = `${this.state.configuracoes.ENDPOINT_URL}/certificados/${id}/verificar`;

    useEffect(() => {

      this.state.getCaptcha("CERTIFICADO").then(captcha => {
        fetch(url, { method: "POST", body: JSON.stringify({ captcha }), headers: { "Content-Type": "application/json" } })
	  .then(response => {
		if (!response.ok){
			throw new Error("Certificado indisponível");
		} else return response.blob();
	  })
          .then(blob => URL.createObjectURL(blob))
          .then(url => {
            const a = document.createElement('a');
            a.href = url;
            a.download = `Certificado-${id.toString().padStart(6, '0')}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
          })
          .catch(error => {
            window.alert("Erro na requisição: " + error.message);
          })
      });

    }, [url, id]);

    return null;
  }

  loadReCaptchaScript = () => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=' + this.state.configuracoes.GOOGLE_SITE_KEY;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  };

  render() {
    return <AppContext.Provider value={this.state}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<AppContainer />} />
          <Route path="/recover/:uuid" element={<RecoverForm />} />
          <Route path="/boletos/regin/:protocolo" Component={this.redirectBoleto} />
          <Route path="/certificados/download/:hash" Component={this.redirectCertificado} />
          <Route path="/verificar/:id" Component={this.redirectVerificar} />
          <Route path="/certificado/validarqr/:id" Component={this.redirectVerificar} />
          <Route element={"Página Não Encontrada"} />
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  }
}

export default App;
