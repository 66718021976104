import { Component } from "react";
import AppContext from "../../AppContext";
import Datatable from "../componentes/Datatable";
import ProtocoloForm from "./ProtocoloForm";
import ProtocoloAndamentos from "./ProtocoloAndamentos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Container, Row } from "react-bootstrap";

class ProtocoloList extends Component {

    static contextType = AppContext;

    state = {
        dashboard: [],
        classe: '',
        filters: [],
        urls: []
    }

    componentWillUnmount() {
        this.state.urls.forEach(url => URL.revokeObjectURL(url));
    }

    onAction(action, callback) {

        switch (action.name) {
            case "Ver Detalhes":
                this.context.request("GET", "/protocolos/" + action.id).then(protocolo =>
                    this.context.setContent(<ProtocoloForm protocolo={protocolo} onCancel={() => this.context.setContent(<ProtocoloList />)} />)
                );
                break;
            case "Fiscalizar":
                this.context.request("GET", "/protocolos/" + action.id).then(async protocolo => {
                    protocolo.Perguntas = (await this.context.request(`GET`, `/protocolos/${protocolo.id}/perguntas`)).map(pergunta => pergunta.id);
                    protocolo.VinculosInstrutores = (await this.context.request(`GET`, `/protocolos/${protocolo.id}/vinculos/instrutores`)).map(vinculo => vinculo.id);
                    protocolo.ProtocoloOriginalId = protocolo.id;
                    protocolo.id = null;
                    protocolo.tipo = `Fiscalização`;
                    protocolo.status = null;
                    this.context.setContent(<ProtocoloForm protocolo={protocolo} onCancel={() => this.context.setContent(<ProtocoloList />)} />);
                });
                break;
            case "Renovar":
                this.context.request("GET", `/cadastros/${action.CadastroId}/debito`).then(({ debito }) => {
                    if (debito) {
                        window.alert("O cadastro possui débitos pendentes relacionados a multa. Regularize as pendências antes de prosseguir com um novo protocolo.")
                    } else {
                        this.context.request("GET", "/protocolos/" + action.id).then(async protocolo => {
                            protocolo.Perguntas = (await this.context.request(`GET`, `/protocolos/${protocolo.id}/perguntas`)).map(pergunta => pergunta.id);
                            protocolo.VinculosInstrutores = (await this.context.request(`GET`, `/protocolos/${protocolo.id}/vinculos/instrutores`)).map(vinculo => vinculo.id);
                            protocolo.ProtocoloOriginalId = protocolo.id;
                            protocolo.id = null;
                            switch (protocolo.tipo) {
                                case "Licenciamento":
                                case "Renovação de Licenciamento":
                                    protocolo.tipo = "Renovação de Licenciamento";
                                    break;
                                case "Credenciamento":
                                case "Renovação de Credenciamento":
                                    protocolo.tipo = "Renovação de Credenciamento";
                                    break;
                                default:
                                    protocolo.tipo = "";
                                    break;
                            }
                            protocolo.status = null;
                            this.context.setContent(<ProtocoloForm protocolo={protocolo} onCancel={() => this.context.setContent(<ProtocoloList />)} />);
                        });
                    }
                });
                break;
            case "Andamentos":
                this.context.request("GET", "/protocolos/" + action.id + "/andamentos").then(andamentos =>
                    this.context.openModal({
                        params: { size: "xl" }, title: "Andamentos", body: <ProtocoloAndamentos ProtocoloId={action.id} andamentos={andamentos} onExit={() => {
                            this.context.closeModal();
                        }} />
                    })
                );
                break;
            case "Baixar Certificado":
                this.context.request("GET", `/certificados/${action.CertificadoId}/download`)
                    .then(blob => this.download(blob, `Certificado-CBMPA-${action.CertificadoId.toString().padStart(6, '0')}.pdf`));
                break
            case "Arquivar":
                this.context.request("PATCH", "/protocolos/" + action.id + "/arquivar").then(protocolo => {
                    this.context.addToast({ header: "Sucesso", body: "Ação realizada com sucesso!" });
                    callback();
                });
                break;
            case "Desarquivar":
                this.context.request("PATCH", "/protocolos/" + action.id + "/desarquivar").then(protocolo => {
                    this.context.addToast({ header: "Sucesso", body: "Ação realizada com sucesso!" });
                    callback();
                });
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com ID " + action.id + "?"))
                    this.context.request("DELETE", "/protocolos/" + action.id).finally(callback);
                break;
            default:
                break;
        }
    }

    download(blob, filename) {
        return new Promise(resolve => {
            const url = URL.createObjectURL(blob);
            this.setState(state => ({ urls: [...state.urls, url] }), () => {
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                resolve();
            });
        });
    }

    render() {
        return <>
            <Container>
                <h2><FontAwesomeIcon icon={faFile} /> Pesquisa de Protocolos</h2>
                {this.state.filters.length ? <h4><i>{this.state.filters.map(f => `${f.label}: ${f.value}`).join(', ')}</i></h4> : ""}
                {this.context.usuario.classe === "usuario" && <Row>
                    <Col lg={4} className="mb-3 d-flex flex-column">
                        <Card onClick={() => this.setState({ classe: '' })} role="button" bg="dark" className={`flex-grow-1 text-white card-dashboard ${this.state.classe ? null : `card-dashboard-active`}`}>
                            <Card.Header>Todos os Protocolos</Card.Header>
                        </Card>
                    </Col>
                    <Col lg={2} className="mb-3 d-flex flex-column">
                        <Card onClick={() => this.setState({ classe: "arquivo" })} role="button" bg="success" className={`flex-grow-1 text-white card-dashboard ${this.state.classe === "arquivo" ? `card-dashboard-active` : ``}`}>
                            <Card.Header className="text-center">Protocolos Finalizados</Card.Header>
                        </Card>
                    </Col>
                    <Col lg={2} className="mb-3 d-flex flex-column">
                        <Card onClick={() => this.setState({ classe: "usuario" })} role="button" bg="warning" className={`flex-grow-1 text-white card-dashboard ${this.state.classe === "usuario" ? `card-dashboard-active` : ``}`}>
                            <Card.Header className="text-center">Designados ao CBMPA</Card.Header>
                        </Card>
                    </Col>
                    <Col lg={2} className="mb-3 d-flex flex-column">
                        <Card onClick={() => this.setState({ classe: "cadastro" })} role="button" bg="primary" className={`flex-grow-1 text-white card-dashboard ${this.state.classe === "cadastro" ? `card-dashboard-active` : ``}`}>
                            <Card.Header className="text-center">Designados ao Interessado</Card.Header>
                        </Card>
                    </Col>
                    <Col lg={2} className="mb-3 d-flex flex-column">
                        <Card onClick={() => this.setState({ classe: "encarregado" })} role="button" bg="secondary" className={`flex-grow-1 text-white card-dashboard ${this.state.classe === "encarregado" ? `card-dashboard-active` : ``}`}>
                            <Card.Header className="text-center">Designados ao Encarregado</Card.Header>
                        </Card>
                    </Col>
                </Row>}
            </Container>
            <Datatable
                onChangeFilters={filters => this.setState({ filters: filters.filter(f => f.value) })}
                filters={[
                    { name: "id", label: "Protocolo", span: 2 },
                    { name: "cnpj_cpf", label: "CNPJ/CPF", op: "clean", span: 2 },
                    { name: "nome_estabelecimento", label: "Nome/Estabelecimento", span: 4 },
                    { name: "$Unidade.sigla$", label: "Unidade", span: 2 },
                    { name: "$Cidade.nome$", label: "Cidade", span: 2 },
                    { name: "$Bairro.nome$", label: "Bairro", span: 2 },
                    { name: "endereco", label: "Endereço", span: 4 },
                    {
                        name: "tipo", label: "Tipo", span: 2, options: [
                            "Licenciamento",
                            "Renovação de Licenciamento",
                            "Credenciamento",
                            "Renovação de Credenciamento",
                            "Fiscalização",
                            "Outros Serviços"
                        ]
                    },
                    {
                        name: "canal", label: "Canal", span: 2, options: ["WEB", "REGIN", "CBM"]
                    },
                    {
                        name: "status", label: "Status", span: 2, options: [
                            "Indefinido",
                            "Advertência Aplicada",
                            "Advertência Emitida",
                            "Advertência Finalizada sem Pendências",
                            "Análise de Credenciamento Agendada",
                            "Análise de Credenciamento Aprovada",
                            "Análise de Credenciamento Reprovada",
                            "Análise de Projeto Agendada",
                            "Análise de Projeto Aprovada",
                            "Análise de Projeto Reprovada",
                            "Análise de Projeto Solicitada",
                            "Análise de Renovação de Credenciamento Agendada",
                            "Análise de Renovação de Credenciamento Aprovada",
                            "Análise de Renovação de Credenciamento Reprovada",
                            "Anotação Emitida",
                            "Anotação Protocolada",
                            "Auto de Infração Emitido",
                            "Autuação Finalizada com Pendências",
                            "Autuação Finalizada sem Pendências",
                            "Autuação Protocolada",
                            "Boleto Vencido",
                            "Certificado de Credenciamento Emitido",
                            "Certificado de Licenciamento Cassado",
                            "Certificado de Licenciamento Emitido",
                            "Certificado de Renovação de Credenciamento Emitido",
                            "Certificado de Renovação de Licenciamento Cassado",
                            "Certificado de Renovação de Licenciamento Emitido",
                            "Certificado de TAACB Emitido",
                            "Certificado do Serviço Emitido",
                            "Credenciamento Protocolado",
                            "Credenciamento Reprovado",
                            "Débitos Inscritos em Dívida Ativa",
                            "Declarado Saneamento Preliminar",
                            "Estabelecimento Autuado",
                            "Fiscalização Finalizada com Pendências",
                            "Fiscalização Finalizada sem Pendências",
                            "Fiscalização Protocolada",
                            "Homologação de Licenciamento Agendada",
                            "Homologação de Licenciamento Aprovada",
                            "Homologação de Licenciamento Reprovada",
                            "Inscrição de Débitos Pendente",
                            "Julgamento de Prorrogação Aprovado",
                            "Julgamento de Prorrogação de Prazo Agendado",
                            "Julgamento de Prorrogação Reprovado",
                            "Julgamento de Recurso Agendado",
                            "Julgamento de Recurso de Multa Agendado",
                            "Julgamento de Recurso de Multa Aprovado",
                            "Julgamento de Recurso de Multa Reprovado",
                            "Julgamento de Recurso Preliminar Agendado",
                            "Julgamento de Recurso Preliminar Aprovado",
                            "Julgamento de Recurso Preliminar Reprovado",
                            "Julgamento de TAACB Agendado",
                            "Julgamento de TAACB Aprovado",
                            "Julgamento de TAACB Reprovado",
                            "Licenciamento Protocolado",
                            "Licenciamento Reprovado",
                            "Multa Aplicada",
                            "Multa Complementar Emitida",
                            "Multa Complementar Paga",
                            "Multa de TAACB Emitida",
                            "Multa de TAACB Paga",
                            "Multa Dobrada Emitida",
                            "Multa Dobrada Paga",
                            "Multa Reduzida por Saneamento Emitida",
                            "Multa Reduzida por Saneamento Paga",
                            "Multa Simples Emitida",
                            "Multa Simples Paga",
                            "Prazo de Advertência Expirado",
                            "Prazo de Multa Excedido",
                            "Prazo Prorrogado",
                            "Prorrogação de Prazo Solicitada",
                            "Protocolo Arquivado",
                            "Reanálise de Credenciamento Solicitada",
                            "Reanálise de Projeto Solicitada",
                            "Reanálise de Renovação de Credenciamento Solicitada",
                            "Recurso Apresentado",
                            "Recurso Aprovado",
                            "Recurso de Multa Apresentado",
                            "Recurso Preliminar Apresentado",
                            "Recurso Reprovado",
                            "Renovação de Credenciamento Protocolada",
                            "Renovação de Licenciamento Protocolada",
                            "Revisão de Licenciamento Agendada",
                            "Revisão de Licenciamento Aprovada",
                            "Revisão de Licenciamento Reprovada",
                            "Revistoria de Credenciamento Solicitada",
                            "Revistoria de Licenciamento Solicitada",
                            "Saneamento Declarado",
                            "Serviço Agendado",
                            "Serviço Cancelado",
                            "Serviço Finalizado",
                            "Serviço Protocolado",
                            "TAACB Aprovado",
                            "TAACB Reprovado",
                            "TAACB Solicitado",
                            "Taxa Complementada",
                            "Taxa de Análise de Projeto Emitida",
                            "Taxa de Análise de Projeto Paga",
                            "Taxa de Anotação Emitida",
                            "Taxa de Anotação Paga",
                            "Taxa de Credenciamento Emitida",
                            "Taxa de Credenciamento Paga",
                            "Taxa de Licenciamento Emitida",
                            "Taxa de Licenciamento Paga",
                            "Taxa de Renovação de Credenciamento Emitida",
                            "Taxa de Renovação de Credenciamento Paga",
                            "Taxa de Renovação de Licenciamento Emitida",
                            "Taxa de Renovação de Licenciamento Paga",
                            "Taxa de Serviço Emitida",
                            "Taxa de Serviço Paga",
                            "Taxa de Vistoria de Licenciamento Emitida",
                            "Taxa de Vistoria de Licenciamento Paga",
                            "Vistoria de Constatação Agendada",
                            "Vistoria de Constatação Aprovada",
                            "Vistoria de Constatação Reprovada",
                            "Vistoria de Credenciamento Agendada",
                            "Vistoria de Credenciamento Aprovada",
                            "Vistoria de Credenciamento Reprovada",
                            "Vistoria de Credenciamento Solicitada",
                            "Vistoria de Fiscalização Agendada",
                            "Vistoria de Fiscalização Aprovada",
                            "Vistoria de Fiscalização Reprovada",
                            "Vistoria de Licenciamento Agendada",
                            "Vistoria de Licenciamento Aprovada",
                            "Vistoria de Licenciamento Reprovada",
                            "Vistoria de Saneamento Definitivo Agendada",
                            "Vistoria de Saneamento Definitivo Aprovada",
                            "Vistoria de Saneamento Definitivo Reprovada",
                            "Vistoria de Saneamento Preliminar Agendada",
                            "Vistoria de Saneamento Preliminar Aprovada",
                            "Vistoria de Saneamento Preliminar Reprovada",
                            "Vistoria Sancionatória Agendada",
                            "Vistoria Sancionatória Aprovada",
                            "Vistoria Sancionatória Pendente",
                            "Vistoria Sancionatória Reprovada"
                        ]
                    },
                    { name: "area", label: "Área Mín.", type: "number", op: "gte", span: 2 },
                    { name: "area", label: "Área Máx.", type: "number", op: "lte", span: 2 },
                    { name: "datahora_entrada", label: "Data Inicial", span: 2, type: "date", op: "gte" },
                    { name: "datahora_entrada", label: "Data Final", span: 2, type: "date", op: "lte" },
                ]
                }
                endpoint={`/protocolos/${this.state.classe}`}
                onAction={(action, callback) => this.onAction(action, callback)} />
        </>
    }
}

export default ProtocoloList;