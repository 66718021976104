import { faFastBackward, faKey, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import AppContext from "../../AppContext";

class NovaSenha extends Component {

    static contextType = AppContext;

    state = {
        senha: "",
        senha2: ""
    }

    submit(event) {
        event.preventDefault();

        const {senha, senha2} = this.state;

        if (senha !== senha2) {
            window.alert("As senhas devem ser iguais!");
        } else if (senha.length < 8) {
            window.alert("A senha deve possuir ao menos 8 caracteres!");
        } else if (!/[\d]/.test(senha)) {
            window.alert("A senha deve possuir ao menos 1 dígito!");
        } else if (!/[^\d]/.test(senha)) {
            window.alert("A senha deve possuir ao menos 1 letra!");
        } else {
            this.context.request("POST", "/cadastros/setPasswordHome", { senha })
                .then(() => {
                    this.context.addToast({ header: "Sucesso", body: "Senha alterada com sucesso! Realize o login com o usuário e senha informados." });
                    this.context.logoff();
                })
        }
    }

    render() {
        return <Form onSubmit={event => this.submit(event)}>
            <div className="card card-body bg-light mb-3">
                Digite uma nova senha abaixo, com no mínimo 8 caracteres, 1 letra e 1 dígito.
            </div>
            <Form.Group className="mb-3">
                <Form.Label><b>Senha</b></Form.Label>
                <InputGroup className="mb-3">
                    <InputGroup.Text><FontAwesomeIcon icon={faKey} /></InputGroup.Text>
                    <Form.Control required type="password" value={this.state.senha} onChange={event => this.setState({ senha: event.target.value })} placeholder="Digite a senha" />
                </InputGroup>
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon icon={faKey} /></InputGroup.Text>
                    <Form.Control required type="password" value={this.state.senha2} onChange={event => this.setState({ senha2: event.target.value })} placeholder="Digite novamente" />
                </InputGroup>
            </Form.Group>
            <div className="d-flex justify-content-between">
                <Button variant="primary" type="submit">
                    <FontAwesomeIcon icon={faFastBackward} /> Voltar
                </Button>
                <Button variant="primary" type="submit">
                    <FontAwesomeIcon icon={faPaperPlane} /> Enviar
                </Button>
            </div>
        </Form>
    }
}

export default NovaSenha;